<template>
  <div
    :role="isOrderable ? 'button' : ''"
    class="c-d-flex ui-items-end ui-select-none ui-mx-4"
    :class="shouldShowIcon ? 'c-text-gray-500' : 'c-text-gray-300'"
    @click="handleOrder"
  >
    <span class="c-text-b2">
      {{ label }}
    </span>

    <icon
      v-if="shouldShowIcon"
      :class="arrowIconClass"
      color="var(--c-text-gray-500)"
      class="order-arrow"
      name="arrow-up"
    />
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CTableHeaderColumn',
  components: {
    Icon,
  },
  props: {
    currentOrder: { type: Object, default: null },
    attribute: { type: String, default: null },
    label: { type: String, default: '' },
    isOrderable: { type: Boolean, default: false },
    threeState: { type: Boolean, default: false },
  },
  computed: {
    shouldShowIcon () {
      return this.isOrderable && (this.currentOrder?.attribute === this.attribute);
    },
    arrowIconClass () {
      if (this.shouldShowIcon) {
        return this.currentOrder?.method === 'ASC' ? 'up' : 'down';
      }
      return '';
    },
  },
  methods: {
    handleOrder () {
      if (!this.isOrderable) {
        return;
      }

      if (!this.currentOrder) {
        this.$emit('change-order', { method: 'ASC', attribute: this.attribute });
        return;
      }

      if (this.currentOrder.attribute !== this.attribute) {
        this.$emit('change-order', { method: 'ASC', attribute: this.attribute });
        return;
      }

      if (this.currentOrder.method === 'ASC') {
        this.$emit('change-order', { method: 'DESC', attribute: this.attribute });
      } else if (this.threeState && this.currentOrder.method === 'DESC') {
        this.$emit('change-order', null);
      } else {
        this.$emit('change-order', { method: 'ASC', attribute: this.attribute });
      }
    },
  },
};
</script>

<style scoped>
.order-arrow {
  opacity: 0;
  transform: rotate(0deg);
  transition: all 0.3s;
  left: 1rem;
}

.order-arrow.up {
  opacity: 1;
  transform: rotate(0deg);
}

.order-arrow.down {
  opacity: 1;
  transform: rotate(180deg);
}
</style>
