<template>
  <div
    class="c-table-row ui-p-4 ui-pl-8 ui-flex ui-flex-row c-bg-white ui-w-full"
    :class="{
      'ui-h-16': !rowVariableHeight,
      'hover:c-bg-gray-100': hoverable,
      'ui-cursor-default': !clickable,
      'ui-cursor-pointer': clickable,
    }"
    :style="{ 'word-break': 'break-word' }"
    @click="$emit('click')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CTableRow',
  props: {
    rowVariableHeight: { type: Boolean, default: false },
    hoverable: { type: Boolean, default: false },
    clickable: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.c-table-row {
  box-shadow: inset 0 -1px 0 rgba(153, 153, 153, 0.24);
}

.c-table-row:first-of-type {
  border-radius: 0.5rem 0.5rem 0 0;
}

.c-table-row:last-of-type {
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: unset;
}
</style>
