<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-bg-white ui-p-8">
            <!-- Título -->
            <div class="c-text-h3 c-text-gray ui-mb-2">
              Criar nova permissão
            </div>

            <!-- Subtítulo -->
            <div class="c-text-b2 c-text-gray ui-mb-6">
              A permissão identifica a ação a ser realizada. Exemplos:
              <ul>
                <li>
                  - <i>accounts.user.get</i>: permissão de ler os dados de um usuário do Accounts
                </li>
                <li>
                  - <i>catalogs.categories.create</i>: permissão de criar uma categoria no Catalogs
                </li>
                <li>
                  - <i>toggler.config.list</i>: permissão de listar configurações no Toggler
                </li>
              </ul>
              Hoje, um contexto (ex: <i>accounts</i>) é responsável por habilitar telas no BO, portanto confirme a necessidade da criação de um novo contexto.
            </div>

            <!-- Formulário -->
            <p-form ref="form">
              <!-- Nome -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="name-field"
                    label="Nome"
                    class="ui-mb-4"
                    v-model.trim="permission.name"
                    :validator="validateName"
                  />
                </div>
              </div>

              <!-- Descrição -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="description-field"
                    label="Descrição"
                    class="ui-mb-4"
                    v-model.trim="permission.description"
                    :validator="validateDescription"
                  />
                </div>
              </div>

              <!-- Identificação -->
              <div class="c-text-h5 c-text-gray ui-mb-2 ui-overflow-hidden ui-truncate">
                {{ formatIdentification }}
              </div>

              <!-- Identificação -->
              <div class="c-row ui-mb-4">
                <!-- Contexto -->
                <div class="c-col">
                  <p-input
                    data-cy="id-field"
                    class="ui-mb-2"
                    label="Contexto"
                    placeholder="accounts"
                    v-model.trim="identification.context"
                    :validator="validateIdentificationContext"
                  />
                </div>

                <!-- Recurso -->
                <div class="c-col">
                  <p-input
                    data-cy="id-field"
                    class="ui-mb-2"
                    label="Recurso"
                    placeholder="user"
                    v-model.trim="identification.resource"
                    :validator="validateIdentificationResource"
                  />
                </div>

                <!-- Ação -->
                <div class="c-col">
                  <p-input
                    data-cy="id-field"
                    class="ui-mb-2"
                    label="Ação"
                    placeholder="get"
                    v-model.trim="identification.action"
                    :validator="validateIdentificationAction"
                  />
                </div>
              </div>

              <div class="c-row c-horizontal-end">
                <div class="c-col-1/2">
                  <c-button
                    full
                    outline
                    data-cy="cancelarButton"
                    @click="$modal.hide()"
                    :disabled="isFormSending"
                  >
                    Cancelar
                  </c-button>
                </div>

                <div class="c-col-1/2">
                  <c-button
                    full
                    type="submit"
                    data-cy="saveButton"
                    @click="createPermission"
                    :disabled="!isFormValid()"
                    :loading="isFormSending"
                  >
                    Salvar
                  </c-button>
                </div>
              </div>
            </p-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'CreatePermissionModal',
  components: {
    PInput,
    PForm,
    CButton,
  },
  props: {
    existentContexts: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      isFormSending: false,
      permission: {
        name: '',
        id: null,
        description: '',
        context: '',
      },
      identification: {
        context: '',
        resource: '',
        action: '',
      },
    };
  },
  computed: {
    formatIdentification() {
      const { context, resource, action } = this.identification;
      return `ID: ${context || 'accounts' }.${ resource || 'user' }.${ action || 'get' }`;
    },
  },
  methods: {
    validateName(name) {
      return !name ? 'Nome é obrigatório' : false;
    },
    validateDescription(action) {
      return !action ? 'Descrição é obrigatória' : false;
    },
    validateIdentificationContext(context) {
      return !context ? 'Contexto é obrigatório' : false;
    },
    validateIdentificationResource(resource) {
      return !resource ? 'Recurso é obrigatório' : false;
    },
    validateIdentificationAction(action) {
      return !action ? 'Ação é obrigatória' : false;
    },
    isFormValid() {
      return this.$refs.form?.valid();
    },
    async createPermission () {
      if (this.isFormValid()) {
        const { context, resource, action } = this.identification;

        this.permission.id = `${context}.${resource}.${action}`;
        this.permission.context = context;

        if (!this.existentContexts.includes(this.permission.context)) {
          const accepted = await this.$dialog.show({
            title: 'Criar novo contexto',
            message: `O contexto "${this.permission.context}" não existe, deseja criar?`,
          });

          if (!accepted) return;
        }

        this.isFormSending = true;

        await this.$s.permission.create(this.permission).finally(() => this.isFormSending = false);

        this.$toast.show({ type: 'success', text: 'Permissão criada com sucesso!' });
        this.$modal.hide();

        // Atualiza a página para apresentar o novo registro criado
        this.$router.go({ name: 'permissions' });
      }
    },
  },
};
</script>
