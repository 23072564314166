<template>
  <div>
    <CTableRow
      class="ui-items-center ui-cursor-pointer"
      @click="internalIsOpen = !internalIsOpen "
    >
      <icon
        :class="internalIsOpen ? 'up' : 'down'"
        class="ui-mr-6 order-arrow"
        name="arrow-up"
      />

      <div class="c-text-b2 c-text-primary ui-capitalize">
        {{ title }}
      </div>
    </CTableRow>

    <div
      ref="contentBox"
      class="ui-overflow-hidden"
      style="transition: all 0.3s ease;"
      :style="{ height: internalIsOpen ? 'auto' : 0 }"
    >
      <div class="ui-mx-8 ui-mt-4 ui-mb-6">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';
import CTableRow from '../c-table/CTableRow';

export default {
  name: 'ExpandableRow',
  components: {
    CTableRow,
    Icon,
  },
  data () {
    return {
      internalIsOpen: this.isOpen,
    };
  },
  props: {
    title: { type: String, required: true },
    isOpen: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.order-arrow {
  opacity: 0;
  transform: rotate(0deg);
  transition: all 0.3s;
  left: 1rem;
}

.order-arrow.up {
  opacity: 1;
  transform: rotate(0deg);
}

.order-arrow.down {
  opacity: 1;
  transform: rotate(180deg);
}
</style>
