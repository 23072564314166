<template>
  <div class="ui-flex ui-flex-row ui-items-center ui-box-border ui-overflow-hidden ui-mx-4">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CTableColumn',
};
</script>
