<template>
  <div class="c-row">
    <div class="ui-m-4 ui-mt-8 c-col">
      <!-- Título -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <span class="c-text-h2 c-text-gray-400">
            Permissões
          </span>
        </div>
      </div>

      <!-- Subtítulo e Botão Criar permissão -->
      <div class="c-row c-vertical-center ui-justify-between ui-mb-4">
        <!-- Subtítulo -->
        <div class="c-col md:c-col-8">
          <span class="c-text-subtitle c-text-gray-300">
            Gerencie as permissões do sistema
          </span>
        </div>

        <!-- Botão Criar permissão -->
        <div
          v-if="hasPermission('accounts.permissions.create')"
          class="c-col"
          style="max-width: 264px;"
        >
          <c-button
            full
            data-cy="createPermissionButton"
            @click="create"
          >
            Criar permissão
            <icon
              name="add"
              outline
              class="c-icon--right"
            />
          </c-button>
        </div>
      </div>

      <ExpandableRow
        v-for="permissionGroup of permissionsGroups"
        :key="permissionGroup.context"
        :title="permissionGroup.context"
        :data-cy="`row-${ permissionGroup.context }`"
        class="c-text-b2 c-text-gray-300"
      >
        <c-table
          :columns="columns"
          :data="permissionGroup.permissions"
          row-variable-height
        >
          <template v-slot:column-actions="{ item: permission }">
            <clickable-icon
              v-if="hasPermission('accounts.permissions.update')"
              hoverable
              name="edit"
              :data-cy="`editPermission-${ permission.name }`"
              @click="edit(permission)"
            />
            <clickable-icon
              v-if="hasPermission('accounts.permissions.delete')"
              name="trash"
              class="ui-ml-4"
              :data-cy="`trashPermission-${ permission.name }`"
              @click="openRemovePermissionModal(permission)"
            />
          </template>
        </c-table>
      </ExpandableRow>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import CTable from '@/components/c-table/CTable';
import ClickableIcon from '@/components/clickable-icon';
import EditPermissionModal from './EditPermissionModal';
import CreatePermissionModal from './CreatePermissionModal';
import ExpandableRow from '@/components/expandable-row';

export default {
  components: {
    ClickableIcon,
    ExpandableRow,
    Icon,
    CButton,
    CTable,
  },
  props: {
    loggedUser: { type: Object, required: true },
  },
  data: () => ({
    permissions: [],
    columns: [
      { attribute: 'id', label: 'ID' },
      { attribute: 'name', label: 'Nome' },
      { attribute: 'description', label: 'Descrição' },
      { attribute: 'actions', label: '', justifyContent: 'end' },
    ],
  }),
  async mounted () {
    this.permissions = await this.$s.permission.getAll();
  },
  computed: {
    permissionsGroups () {
      const permissionsAsObj = this.permissions.reduce((acc, permission) => {
        const context = permission.context;
        if (!acc[context]) acc[context] = [];
        acc[context].push(permission);
        return acc;
      }, {});

      return Object.entries(permissionsAsObj)
        .map(([context, permissions]) => ({ context, permissions }))
        .filter(p => p.context !== '*');
    },
  },
  methods: {
    hasPermission (permission) {
      return this.loggedUser?.hasPermission(permission);
    },
    create () {
      const existentContexts = this.permissionsGroups.map(p => p.context);
      this.$modal.show({
        component: CreatePermissionModal,
        overlayOpacity: 0.7,
        props: { existentContexts },
      });
    },
    edit(permission) {
      this.$modal.show({
        overlayOpacity: 0.7,
        component: EditPermissionModal,
        props: { permission },
      });
    },
    async openRemovePermissionModal(permission) {
      const accepted = await this.$dialog.show({
        title: 'Deletar permissão',
        message: `Realmente deseja deletar a permissão "${permission.name}" (${permission.id})?`,
      });

      if (!accepted) return;

      await this.$s.permission.remove(permission._id);

      const index = this.permissions.findIndex(p => p.id === permission.id);
      this.permissions.splice(index, 1);
    },
  },
};
</script>
