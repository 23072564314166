<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-bg-white ui-p-8">
            <!-- Título -->
            <div class="c-text-h3 c-text-gray ui-mb-2">
              Editar permissão
            </div>

            <!-- Formulário -->
            <p-form ref="form">
              <!-- Nome -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="name-field"
                    label="Nome"
                    class="ui-mb-4"
                    v-model.trim="name"
                    :validator="validateName"
                  />
                </div>
              </div>

              <!-- Descrição -->
              <div class="c-row c-horizontal-end">
                <div class="c-col">
                  <p-input
                    data-cy="description-field"
                    label="Descrição"
                    class="ui-mb-4"
                    v-model.trim="description"
                  />
                </div>
              </div>

              <div class="c-row c-horizontal-end">
                <div class="c-col-1/2">
                  <c-button
                    full
                    outline
                    data-cy="cancelarButton"
                    @click="$modal.hide()"
                    :disabled="isFormSending"
                  >
                    Cancelar
                  </c-button>
                </div>

                <div class="c-col-1/2">
                  <c-button
                    full
                    type="submit"
                    data-cy="saveButton"
                    @click="editPermission"
                    :disabled="!isFormValid()"
                    :loading="isFormSending"
                  >
                    Salvar
                  </c-button>
                </div>
              </div>
            </p-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PInput from '@/components/p-input';
import PForm from '@/components/p-form';
import { CButton } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'EditPermissionModal',
  components: {
    PInput,
    PForm,
    CButton,
  },
  props: {
    permission: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      isFormSending: false,
      name: '',
      description: '',
    };
  },
  mounted() {
    const { name, description } = this.permission;

    this.name = name;
    this.description = description;
  },
  methods: {
    validateName(name) {
      return !name ? 'Nome é obrigatório' : false;
    },
    isFormValid() {
      return this.$refs.form?.valid();
    },
    async editPermission () {
      if (this.isFormValid()) {
        this.isFormSending = true;
        const { description, name } = this;
        /* Somente essas propriedades podem ser atualizadas para garantir compatibilidade com accounts 2 */
        await this.$s.permission.update(this.permission._id, { description, name }).finally(() => this.isFormSending = false);

        this.$toast.show({ type: 'success', text: 'Permissão editada com sucesso!' });

        // Atribui os valores para alterar diretamente na listagem
        this.permission.description = description;
        this.permission.name = name;

        this.$modal.hide();
      }
    },
  },
};
</script>
