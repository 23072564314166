<template>
  <button
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    v-on="$listeners"
  >
    <icon
      :filled="shouldFill"
      :name="name"
      :color="color"
    />
  </button>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'ClickableIcon',
  components: { Icon },
  props: {
    name: { type: String, default: '' },
    color: { type: String, default: undefined },
    hoverable: { type: Boolean, default: false },
    filled: { type: Boolean, required: false },
  },
  data: () => ({
    isHovered: false,
  }),
  computed: {
    shouldFill () {
      return this.filled || (this.hoverable && this.isHovered);
    },
  },
};
</script>
