<template>
  <div class="table-header-wrapper ui-p-4 ui-pl-8 ui-flex ui-flex-row ui-w-full">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CTableHeaderRow',
};
</script>

<style scoped>
.table-header-wrapper {
  box-shadow: inset 0 -1px 0 rgba(153, 153, 153, 0.24);
}
</style>
