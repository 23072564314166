<template>
  <div>
    <!-- Dados da tabela -->
    <div class="ui-flex ui-flex-col ui-w-full ui-mb-8">
      <c-table-header-row>
        <c-table-header-column
          v-for="(column, index) in columns"
          :key="`table-${name}-${index}-${column.attribute}`"
          :label="column.label"
          :attribute="column.attribute"
          :is-orderable="column.isOrderable"
          :current-order="internalOrderBy"
          @change-order="onUpdateOrderBy"
        />
      </c-table-header-row>

      <div class="table-content ui-flex ui-flex-col ui-w-full">
        <c-table-row
          v-for="row in data"
          :key="row.id"
          :row-variable-height="rowVariableHeight"
          :clickable="isClickable"
          hoverable
          @click="$emit('click', row.id)"
        >
          <c-table-column
            v-for="column in columns"
            :key="column.attribute"
          >
            <slot
              :name="`column-${column.attribute}`"
              :item="row"
            >
              {{ row[column.attribute] }}
            </slot>
          </c-table-column>
        </c-table-row>
      </div>
    </div>

    <!-- Pagination -->
    <div
      v-if="internalPagination"
      class="c-row ui-flex ui-flex-row ui-items-center ui-justify-center ui-px-10"
    >
      <c-paginator
        type="large"
        class="c-row"
        :pages="internalPagination.totalPages"
        :page="internalPagination.currentPage"
        @change="onUpdateCurrentPage"
      />
      <div
        v-if="showItemsPerPage"
        class="per-page-dropdown c-row ui-mt-2"
      >
        <c-dropdown-small
          placement="left-top"
          :options="paginationDropdownOptions"
          :value="paginationValue"
          @change="onUpdatePerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { CDropdownSmall, CPaginator } from '@estrategiahq/coruja-web-ui';
import CTableRow from './CTableRow';
import CTableHeaderRow from './CTableHeaderRow';
import CTableHeaderColumn from './CTableHeaderColumn';
import CTableColumn from './CTableColumn';

const valueToDropdownValue = (value) => ({ label: `${value}`, value });

export default {
  name: 'CTable',
  components: {
    CTableRow,
    CTableColumn,
    CTableHeaderRow,
    CTableHeaderColumn,
    CDropdownSmall,
    CPaginator,
  },
  props: {
    name: { type: String, default: 'default-table-name' },
    columns: { type: Array, default: () => [] },
    data: { type: Array, required: true },
    rowVariableHeight: { type: Boolean, default: false },
    currentOrderBy: { type: Object, default: () => ({}) },
    pagination: { type: Object, default: null },
    itemsPerPage: { type: Array, default: () => [10, 20, 30, 40, 50] },
    isClickable: { type: Boolean, default: false },
    showItemsPerPage: { type: Boolean, default: true },
  },
  data () {
    return {
      internalPagination: this.pagination,
      internalOrderBy: this.currentOrderBy,
    };
  },
  watch: {
    data: {
      immediate: true,
      handler: function () {
        this.$nextTick(() => {
          this.$children.forEach(row => {
            if ([CTableHeaderRow.name, CTableRow.name].includes(row.$options.name)) {
              row.$children.forEach((column, index) => {
                column.$el.style.maxWidth = this.columns[index].maxWidth || '';
                column.$el.style.flex = this.columns[index].flexWeight || '1';
                column.$el.style.width = `${100 / this.columns.length}%`;
                column.$el.style.justifyContent = this.columns[index].justifyContent || '';
              });
            }
          });
        });
      },
    },
  },
  computed: {
    paginationDropdownOptions () {
      return this.itemsPerPage.map(valueToDropdownValue);
    },
    paginationValue () {
      return valueToDropdownValue(this.internalPagination.perPage);
    },
  },
  methods: {
    onUpdatePerPage ({ value }) {
      this.internalPagination.perPage = value;
      this.$emit('change-pagination-per-page', value);
    },
    onUpdateCurrentPage (value) {
      this.internalPagination.currentPage = value;
      this.$emit('change-pagination-current-page', value);
    },
    onUpdateOrderBy (value) {
      this.internalOrderBy = value;
      this.$emit('change-order-by', value);
    },
  },
};
</script>

<style scoped>
.table-content {
  box-shadow: 0 8px 32px rgba(34, 34, 34, 0.08);
  border-radius: 0.5rem;
}
</style>
